<template>
  <v-menu
    v-model="show"
    :close-on-content-click="false"
    transition="scale-transition"
    min-width="auto"
    max-width="500"
    :max-height="showTimes ? 380 : 310"
    offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="dateRangeText"
        :label="dateRangeText ? 'Date Range' : 'All Dates'"
        prepend-inner-icon="mdi-calendar"
        v-bind="attrs"
        outlined
        :dense="dense"
        hide-details="auto"
        @click:prepend-inner="show = !show"
        v-on="on"
      />
    </template>
    <div style="background: var(--v-light-background-base)">
      <v-row class="pt-4">
        <v-col cols="5" class="px-5 pt-4">
          <v-btn
            v-for="(button, i) in calendarPresets"
            :key="i"
            class="my-2"
            block
            @click="
              monthCalendarView = false
              jumpToDate(button.days, !button.singleDay)
            "
          >
            {{ button.title }}
          </v-btn>
          <v-btn
            v-if="monthCalendarView"
            class="my-2"
            block
            @click="monthCalendarView = false"
          >
            Custom Days
          </v-btn>
          <v-btn v-else class="my-2" block @click="monthCalendarView = true">
            Custom Months
          </v-btn>
          <v-checkbox
            v-if="showTimes"
            v-model="customTime"
            label="Customize Time Range"
            dense
          />
        </v-col>
        <v-col cols="7" class="pl-0 pt-0">
          <v-row>
            <v-col>
              <v-date-picker
                v-model="dates"
                show-adjacent-months
                full-width
                no-title
                :events="events"
                range
                :type="monthCalendarView ? 'month' : 'date'"
                @change="dateChanged"
              />
            </v-col>
          </v-row>
          <v-row v-if="showTimes" class="pt-0 mt-0">
            <v-col cols="6" class="pt-0 mt-1">
              <v-text-field
                v-model="times[0]"
                step="1"
                :disabled="!customTime"
                label="From time"
                type="time"
                class="mr-1 mb-n1"
                @change="dateChanged"
              />
            </v-col>
            <v-col cols="6" class="pt-0 mt-1">
              <v-text-field
                v-model="times[1]"
                step="1"
                :disabled="!customTime"
                label="To time"
                type="time"
                class="mr-3 ml-n2 mb-n1"
                @change="dateChanged"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </v-menu>
</template>

<script>
import moment from "moment"

export default {
  name: "custom-date-range-filter",
  props: {
    events: {
      type: Array,
      required: false,
      default: () => [],
    },
    initialDates: {
      type: Array,
      required: false,
      default: () => [],
    },
    dense: {
      type: Boolean,
      required: false,
      default: false,
    },
    showTimes: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      show: false,
      dates: [],
      customTime: false,
      times: ["00:00:00", "23:59:59"],
      monthCalendarView: false,
    }
  },
  computed: {
    dateRangeText: {
      get() {
        if (this.dates.length === 0) {
          return ""
        }

        const sortedDates = [...this.dates].sort()

        const thisYear =
          moment(sortedDates[0], "YYYY-MM-DD").format("YYYY") ===
          moment().format("YYYY")

        const sameYear =
          moment(sortedDates[0], "YYYY-MM-DD").format("YYYY") ===
          moment(sortedDates[1], "YYYY-MM-DD").format("YYYY")
        const sameMonth =
          moment(sortedDates[0], "YYYY-MM-DD").format("MMM") ===
          moment(sortedDates[1], "YYYY-MM-DD").format("MMM")

        // Presets
        for (var preset of Object.values(this.calendarPresets)) {
          if (
            sortedDates[0] ===
              moment().subtract(preset.days, "days").format("YYYY-MM-DD") &&
            sortedDates[1] ===
              moment()
                .subtract(preset.singleDay ? preset.days : 0, "days")
                .format("YYYY-MM-DD")
          ) {
            return preset.title
          }
        }

        // Single day
        if (sortedDates.length === 1 || sortedDates[0] === sortedDates[1]) {
          return moment(sortedDates[0], "YYYY-MM-DD").format(
            "MMM DD" + (thisYear ? "" : ", YY")
          )
        }

        // Full year(s)
        if (
          moment(sortedDates[0], "YYYY-MM-DD").format("MM-DD") === "01-01" &&
          moment(sortedDates[1], "YYYY-MM-DD").format("MM-DD") === "12-31"
        ) {
          if (sameYear) {
            return moment(sortedDates[0], "YYYY-MM-DD").format("YYYY")
          }
          return (
            moment(sortedDates[0], "YYYY-MM-DD").format("YYYY") +
            " - " +
            moment(sortedDates[1], "YYYY-MM-DD").format("YYYY")
          )
        }

        // Full month(s)
        if (
          moment(sortedDates[0], "YYYY-MM-DD").format("DD") === "01" &&
          parseFloat(moment(sortedDates[1], "YYYY-MM-DD").format("D")) ===
            moment(sortedDates[1], "YYYY-MM-DD").daysInMonth()
        ) {
          if (sameMonth) {
            return moment(sortedDates[0], "YYYY-MM-DD").format("MMMM YYYY")
          }
          return (
            moment(sortedDates[0], "YYYY-MM-DD").format(
              "MMM" + (sameYear ? "" : " YY")
            ) +
            " - " +
            moment(sortedDates[1], "YYYY-MM-DD").format("MMM YY")
          )
        }

        // Last # of days, weeks, or years
        if (sortedDates[1] === moment().format("YYYY-MM-DD")) {
          if (
            moment(sortedDates[0], "YYYY-MM-DD").format("MM-DD") ===
            moment(sortedDates[1], "YYYY-MM-DD").format("MM-DD")
          ) {
            const years = Math.round(
              moment
                .duration(moment().startOf("day") - moment(sortedDates[0]))
                .asYears()
            )
            return "Last " + years + (years === 1 ? " year" : " years")
          }
          const days = Math.round(
            moment
              .duration(moment().startOf("day") - moment(sortedDates[0]))
              .asDays()
          )
          if (days % 7 === 0) {
            return "Last " + days / 7 + (days === 7 ? " week" : " weeks")
          }
          return "Last " + days + (days === 1 ? " day" : " days")
        }

        // Default
        return [...sortedDates]
          .map((d, i) => {
            if (sameYear && sameMonth) {
              if (i === 0) {
                return moment(d, "YYYY-MM-DD").format(
                  "MMM DD" + (thisYear ? "" : ", YY")
                )
              } else {
                return moment(d, "YYYY-MM-DD").format("DD")
              }
            } else if (sameYear) {
              if (i === 0) {
                return moment(d, "YYYY-MM-DD").format(
                  "MMM DD" + (thisYear ? "" : ", YY")
                )
              } else {
                return moment(d, "YYYY-MM-DD").format("MMM DD")
              }
            } else {
              return moment(d, "YYYY-MM-DD").format("MMM DD, YY")
            }
          })
          .join(" - ")
      },
      set(dateRangeText) {
        if (!dateRangeText) {
          this.dates = []
          return
        }
        const datesToSet = dateRangeText.split(" - ")
        const validDates =
          Array.isArray(datesToSet) &&
          datesToSet.length > 0 &&
          moment(datesToSet[0], "MMM DD", true).isValid()
        if (validDates) {
          const hasEndDate =
            datesToSet.length === 2 &&
            moment(datesToSet[1], "MMM DD", true).isValid()
          if (!hasEndDate) {
            datesToSet[1] = datesToSet[0]
          }
          this.dates = [
            moment(datesToSet[0], "MMM DD").format("YYYY-MM-DD"),
            moment(datesToSet[1], "MMM DD").format("YYYY-MM-DD"),
          ]
        }
      },
    },
    calendarPresets() {
      return {
        today: { title: "Today", days: 0, singleDay: true },
        yesterday: { title: "Yesterday", days: 1, singleDay: true },
        last7: { title: "Last 7 days", days: 7, singleDay: false },
        lastMonth: { title: "Last 30 days", days: 30, singleDay: false },
        lastYear: { title: "Last year", days: 364, singleDay: false },
      }
    },
  },
  watch: {
    dates() {
      if (this.dates.length === 1) {
        if (this.showTimes) {
          this.$emit("change", [
            this.dates[0] + " " + this.times[0],
            this.dates[0] + " " + this.times[1],
          ])
        } else {
          this.$emit("change", [this.dates[0], this.dates[0]])
        }
      }
    },
    customTime(isCustom) {
      if (!isCustom) {
        this.times = ["00:00:00", "23:59:59"]
      }
    },
  },
  async mounted() {
    // this.jumpToDate(this.calendarPresets.lastMonth.days)
    this.dates = this.initialDates
  },
  methods: {
    dateChanged() {
      if (this.dates[0].length === 7 && this.dates[1].length === 7) {
        this.dates[0] = moment(this.dates[0], "YYYY-MM").format("YYYY-MM-DD")
        this.dates[1] = moment(this.dates[1], "YYYY-MM")
          .endOf("month")
          .format("YYYY-MM-DD")
      }
      const sortedDates = [...this.dates].sort()
      if (this.dates.length === 1) {
        sortedDates.push(sortedDates[0])
      }
      if (this.showTimes) {
        this.$emit("change", [
          sortedDates[0] + " " + this.times[0],
          sortedDates[1] + " " + this.times[1],
        ])
      } else this.$emit("change", [sortedDates[0], sortedDates[1]])
      this.show = false
    },
    jumpToDate(days, untilToday = true) {
      this.dates = [
        moment().subtract(days, "days").format("YYYY-MM-DD"),
        moment()
          .subtract(untilToday ? 0 : days, "days")
          .format("YYYY-MM-DD"),
      ]
      this.dateChanged()
    },
  },
}
</script>
